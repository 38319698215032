<template>
  <aside class="custom-aside">
    <div class="d-table">
      <img
        id="main-logo"
        class=""
        src="@/assets/images/mandarin_red.svg"
        alt="Mandarin"
      />
    </div>
    <nav id="left-menu-new" role="navigation">
      <div class="separator-top">
        <!--<div class="separator-line"></div>-->
      </div>
      <ul class="menu left-menu-new list-unstyled">
        <li v-for="(item, index) in menuItems" :key="index" :class="item.type">
          <div v-if="item.type === 'separator'" class="separator-line"></div>
          <router-link
            v-if="showRouterLink(item)"
            :to="item.to"
            :class="computedClasses(item)"
          >
            <div class="label-text">
              <span>{{ item.label }}</span>
            </div>
          </router-link>
          <a
            v-if="showExternalLink(item)"
            :href="item.href"
            :class="computedClasses(item)"
          >
            <div class="label-text">
              <span>{{ item.label }}</span>
            </div>
          </a>
        </li>
        <li id="old-lk" v-if="checkScopePresence('hl_app.read')">
          <a :href="arm_lk_url">
            <div class="label-text"><span>Старый ЛК</span></div>
          </a>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
export default {
  name: "MainSidebar",
  data() {
    return {
      arm_lk_url:
        this.removeTrailingSlash(VUE_APP_ARM_LK) +
        "/Home/List?access_token=" +
        this.$store.state.account.accessToken,
    }
  },

  computed: {
    merchantVue3Url: function () {
      return this.removeTrailingSlash(VUE_APP_MERCHANT_VUE3_URL)
    },
    menuItems: function () {
      // В этом месте мы собственно генерим тот самый массив элементов меню
      // который будет выводиться в менюшке.

      let roles = []
      let menuItems = []
      let item = {}

      /* Это старый функционал Продаж и Лидов, заточенный на займы Горгаранта.
      Поскольку Горгарант займы больше не выдает, ветка больше не нужна;
      оставляю тут пока что этот код для последующей большой чистки и удаления
      всего относящегося к LifeMerchant кода, для референса.
      item = {
        weight: 3,
        to: '/life_merchant/analytics/pending',
        classes: '',
        icon: 'analytics',
        label: 'Продажи'
      }
      roles = [
        'visual_life_merchant',
        '_cl_life_merchant'
      ]
      if (this.checkRolePresence(roles)) {
        menuItems.push(item)
      }

      item = {
        weight: 5,
        to: '/life_merchant/rewards/acts',
        classes: '',
        icon: 'credit_card',
        label: 'Лиды'
      }
      roles = [
        'visual_life_merchant',
        '_cl_life_merchant'
      ]
      if (this.checkRolePresence(roles)) {
        menuItems.push(item)
      }
      */

      item = {
        type: "menu-item",
        weight: 1,
        href: this.merchantVue3Url + "/main",
        classes: "",
        icon: "circle-multiple-outline",
        label: "Главная",
      }
      // Для всех
      menuItems.push(item)

      item = {
        type: "menu-item",
        weight: 6,
        to: "/loan_requests",
        classes: "",
        icon: "circle-multiple-outline",
        label: "Рассрочки",
      }
      roles = ["clients.life_merchant.basic"]
      if (this.checkRolePresence(roles)) {
        menuItems.push(item)
        //life-api:core.applications.read
      }

      item = {
        type: "menu-item",
        weight: 9,
        to: "/payments",
        classes: "",
        icon: "wallet-outline",
        label: "Приём",
      }
      roles = ["clients.pay_api.basic"]
      if (this.checkRolePresence(roles)) {
        menuItems.push(item)
        //transactions.read
      }

      item = {
        type: "menu-item",
        weight: 2,
        href: this.merchantVue3Url+'/invoices/links',
        classes: '',
        icon: 'credit-card-check-outline',
        label: 'Ссылки'
      }
      roles = ["clients.invoices"]
      if (this.checkRolePresence(roles)) {
        menuItems.push(item)
      }

      item = {
        type: "menu-item",
        weight: 11,
        to: "/payouts",
        classes: "",
        icon: "hand-coin-outline",
        label: "Выплаты",
      }
      roles = ["clients.pay_api.basic"]
      if (this.checkRolePresence(roles)) {
        menuItems.push(item)
        //transactions.read
      }

      item = {
        type: "menu-item",
        weight: 12,
        to: "/bindings",
        classes: "",
        icon: "account-credit-card-outline",
        label: "Привязки",
      }
      roles = ["clients.pay_api.basic"]
      if (this.checkRolePresence(roles)) {
        menuItems.push(item)
      }

      item = {
        type: "menu-item",
        weight: 13,
        to: "/receipts",
        classes: "",
        icon: "receipt-text-check-outline",
        label: "Чеки",
      }
      roles = ["clients.pay_api.basic", "clients.life_merchant.basic"]
      if (this.checkRolePresence(roles)) {
        menuItems.push(item)
      }

      item = {
        type: "menu-item",
        weight: 14,
        to: "/pos",
        classes: "",
        icon: "file-document-check-outline",
        label: "POS-Заявки",
      }
      roles = ["clients.life_lender.basic"]
      if (this.checkRolePresence(roles)) {
        menuItems.push(item)
      }

      item = {
        type: "menu-item",
        weight: 15,
        href: this.merchantVue3Url + "/history",
        classes: "",
        icon: "poll",
        label: "История",
      }
      // Для всех
      menuItems.push(item)

      item = {
        type: "separator",
        weight: 16,
      }
      menuItems.push(item)

      item = {
        type: "menu-item",
        weight: 17,
        href: this.merchantVue3Url + "/partner-rewards",
        classes: "",
        icon: "handshake-outline",
        label: "Партнерство",
      }
      roles = ["clients.partner_rewards"]
      if (this.checkRolePresence(roles)) {
        menuItems.push(item)
      }

      item = {
        type: "menu-item",
        weight: 18,
        to: "/export",
        classes: "",
        icon: "file-download-outline",
        label: "Экспорт",
      }
      roles = ["clients.self_user.all"]
      if (this.checkRolePresence(roles)) {
        menuItems.push(item)
      }

      item = {
        type: "menu-item",
        weight: 24,
        to: "/projects",
        classes: "",
        icon: "file-download-outline",
        label: "Проекты",
      }
      roles = ["clients.basic", "clients.advanced", "clients.onboarding"]
      if (this.checkRolePresence(roles)) {
        menuItems.push(item)
      }

      item = {
        type: "menu-item",
        weight: 25,
        to: "/pay_merchant/nocode/modules",
        classes: "",
        icon: "application-brackets-outline",
        label: "Модули",
      }
      roles = [
        "clients.nocode.life_module.edit",
        "clients.nocode.modules_all.read",
        "clients.nocode.pay_module.edit",
        "clients.nocode.payout_module.edit",
        "clients.nocode.transactions_all.read",
      ]
      if (this.checkRolePresence(roles)) {
        menuItems.push(item)
      }

      // Сортируем наше меню по весу, для удобства.
      menuItems.sort(this.compareByWeight)
      return menuItems
    },
  },
  methods: {
    showRouterLink(item) {
      if (
        item.type === "menu-item" &&
        Object.prototype.hasOwnProperty.call(item, "to")
      ) {
        return true
      }
      return false
    },
    showExternalLink(item) {
      if (
        item.type === "menu-item" &&
        Object.prototype.hasOwnProperty.call(item, "href")
      ) {
        return true
      }
      return false
    },
    compareByWeight(a, b) {
      return a.weight - b.weight
    },
    computedClasses(item) {
      if (!Object.prototype.hasOwnProperty.call(item, "to")) {
        return false
      }
      // Для конкретного случая вкладок Лайф-Мерчанта, в которых пути не следуют стандартной конвенции, вычисляем
      // активную левую вкладку сами, не полагаясь на логику конмпонента.
      if (item.to.includes("/life_merchant/analytics")) {
        if (
          Object.prototype.hasOwnProperty.call(this.$route.params, "arg1") &&
          this.$route.params.arg1 === "analytics"
        ) {
          return "router-link-active"
        }
      }
      if (item.to.includes("/life_merchant/rewards")) {
        if (
          Object.prototype.hasOwnProperty.call(this.$route.params, "arg1") &&
          this.$route.params.arg1 === "rewards"
        ) {
          return "router-link-active"
        }
      }
    },
  },
  mounted() {
    // Если мы находимся в корне, то редиректим пользователя на первый доступный ему пункт меню.
    if (this.$route.name === "Home" && this.menuItems.length > 0) {
      // Если это пункт меню с to, то на to, а если там href, то на href
      if (Object.prototype.hasOwnProperty.call(this.menuItems[0], "to")) {
        return this.$router.push(this.menuItems[0].to)
      }
      if (Object.prototype.hasOwnProperty.call(this.menuItems[0], "href")) {
        window.location = this.menuItems[0].href
      }
    }
  },
}
</script>

<style lang="scss" scoped>
#main-logo {
  margin-top: 28px;
  margin-bottom: 35px;
  max-width: 106px;
  position: relative;
  left: 2px;
}

.custom-aside {
  float: left;
  padding-left: 0px;
  width: 160px;
}
nav#left-menu-new {
  font-family: Roboto, sans-serif;
  li {
    height: 35px;
    line-height: 35px;
    a {
      color: rgb(0, 0, 0);

      div.icon {
        display: inline-block;
        margin-right: 6px;
        margin-left: 16px;
        position: relative;
        top: -3px;
        color: #a6a6a6;
      }
      div.label-text {
        display: inline-block;
        font-size: 16px;
        font-weight: 300;
        padding-left: 15px;
      }
      &.router-link-exact-active {
        color: #357bf6;
        background-color: transparent;
        div.icon {
          color: #357bf6;
        }
        div.label-text {
          font-weight: 400;
        }
      }
    }
  }
}
</style>

<style scoped>
.d-table {
  margin-left: 15px;
}
.separator-top {
  height: 27px;
}
.separator-line {
  width: 129px;
  margin-left: auto;
  margin-right: auto;
  height: 1px;
  background-color: #d2d2d2;
}
nav#left-menu-new li.separator {
  height: 17px;
  padding-top: 6px;
}
</style>
