const filters_config = {
  status: {
    show: true,
    options: [
      { value: "C0,P0,L11,A16", label: "Кредит выдан", selected: false },
      {
        value: "R12,R13,R14,R15,R16,R17,R18",
        label: "Отказ клиента",
        selected: false,
      },
      { value: "R1", label: "Отказ кредиторов", selected: false },
      {
        value: "A13,A10,A4,R8,C2,A11,L13,L7,L9,C3,L10,L4,V1",
        label: "На подписании",
        selected: false,
      },
      { value: "A12,A15,A17", label: "На рассмотрении", selected: false },
      { value: "A0,A1", label: "Новая заявка", selected: false },
      { value: "A2,A14,A5", label: "Заполнение анкеты", selected: false },
      { value: "R5", label: "Возврат", selected: false },
      { value: "P2", label: "Средства переведены", selected: false },
    ],
    codes: {
      A0: ["BasketReceived", "Заявка передана в Mandarin", "Новая заявка"],
      A1: [
        "ContactsReceived",
        "Получен номер телефона клиента",
        "Новая заявка",
      ],
      A2: [
        "PhoneConfirmed",
        "Телефон клиента подтвержден",
        "Заполнение анкеты",
      ],
      A14: [
        "AppSentToCallCenter",
        "Заявка передана на прозвон в кол-центр",
        "Заполнение анкеты",
      ],
      A12: [
        "AppSentToFO",
        "Заявка направлена в финансовые организации",
        "На рассмотрении",
      ],
      R16: [
        "CustomerCallFailed",
        "КЦ не дозвонился до клиента",
        "Отказ клиента",
      ],
      R17: [
        "CustCancelBeforeAppr",
        "Отказ клиента до получения предложений",
        "Отказ клиента",
      ],
      A15: ["ApprScoring", "Получено одобрение Mandarin", "На рассмотрении"],
      A17: ["AwaitingClientData", "Ручная проработка", "На рассмотрении"],
      A13: ["ApprFO", "Одобрено финансовой организацией", "На подписании"],
      R1: [
        "CreditRejectedByFinOrgs",
        "Отказ финансовых организаций",
        "Отказ кредиторов",
      ],
      A10: [
        "WaitAccept",
        "Ожидание от клиента акцепта кредита",
        "На подписании",
      ],
      A4: [
        "PaymentInitiated",
        "Инициирована оплата первоначального взноса",
        "На подписании",
      ],
      R8: [
        "CardHoldFail",
        "Ошибка холдирования средств с карты",
        "На подписании",
      ],
      C2: [
        "CardHoldSuccess",
        "Средства с карты успешно захолдированы",
        "На подписании",
      ],
      A11: ["Accepted", "Получен акцепт клиента", "На подписании"],
      R18: [
        "CustCancelAfterAppr",
        "Отказ клиента после получения предложений",
        "Отказ клиента",
      ],
      L13: [
        "DeliveryDateReceived",
        "Получена дата подписания",
        "На подписании",
      ],
      L7: [
        "WaitSignFO",
        "Ожидание подписания документов финансовой организацией",
        "На подписании",
      ],
      L9: [
        "PostponedSignDate",
        "Дата подписания документов перенесена",
        "На подписании",
      ],
      C3: ["LoanOpened", "Ссуда открыта", "На подписании"],
      L10: [
        "CourierSentToCustomer",
        "Курьер Mandarin выехал к клиенту",
        "На подписании",
      ],
      R14: [
        "RejectedMeetingCourier",
        "Отказ от встречи с курьером",
        "Отказ клиента",
      ],
      R13: [
        "DenAtSign7S",
        "Отказ курьера Mandarin на этапе подписания документов",
        "Отказ клиента",
      ],
      R15: [
        "DenAtSignCust",
        "Отказ клиента на этапе подписания документов",
        "Отказ клиента",
      ],
      R12: [
        "DenAtSignFO",
        "Отказ на этапе подписания документов курьером финансовой организации",
        "Отказ клиента",
      ],
      L4: [
        "ScanSendToFO",
        "Сканы документов отправлены в финансовую организацию",
        "На подписании",
      ],
      V1: ["VerificationPassed", "Верификация пройдена", "На подписании"],
      C0: ["CreditApproved", "Кредит подписан", "Кредит выдан"],
      P0: [
        "UploadedTo1C",
        "Ожидание денег от финансовой организации",
        "Кредит выдан",
      ],
      P2: [
        "FundsPaidToMerchant",
        "Средства переведены в магазин",
        "Средства переведены",
      ],
      L11: [
        "WaitingForGoodsShipment",
        "Ожидание отгрузки товара",
        "Кредит выдан",
      ],
      A16: [
        "ApplicationSuccessfullyExecuted",
        "Заявка успешно выполнена",
        "Кредит выдан",
      ],
      A5: [
        "PersonIdentificationSkipped",
        "Идентификация пропущена",
        "Заполнение анкеты",
      ],
    },
  },
  status_loans: {
    show: true,
    options: [
      { value: "C0,P0,L11,A16", label: "Выдан", selected: false },
      { value: "R5", label: "Возврат", selected: false },
      { value: "P2", label: "Средства переведены", selected: false },
    ],
  },
}

export default filters_config
